<script setup lang="ts">
import PromoCodeTiers from "~/components/TopBanner/Promos/PromoCodeTiers.vue";
import PromoBanner from "~/components/TopBanner/Promos/PromoBanner.vue";
import ReferralBanner from "~/components/TopBanner/Referrals/ReferralBanner.vue";
import ReferralRedemptionLimitBanner from "~/components/TopBanner/Referrals/ReferralRedemptionLimitBanner.vue";

useHead({
  link: [
    {
      rel: "stylesheet",
      href: "/assets/compile/tailwind.css",
    },
  ],
});
</script>

<template>
  <div
    class="tw-min-h-[calc(var(--vh)-var(--om-floating-height))] tw-flex tw-flex-col tw-bg-nl-yellow-100 tw-text-nl-green-100 tw-antialiased tw-text-body-sm md:tw-text-body tw-overflow-x-clip"
  >
    <div
      class="tw-sticky tw-top-[var(--om-floating-height)] tw-right-0 tw-left-0 tw-z-[51]"
    >
      <client-only>
        <PromoCodeTiers/>
        <PromoBanner/>
      </client-only>
      <ReferralBanner/>
      <ReferralRedemptionLimitBanner/>
      <Header/>
    </div>

    <main id="content" class="tw-flex-1 tw-flex tw-flex-col">
      <slot/>
    </main>
    <Footer/>
  </div>
</template>

<style scoped></style>
